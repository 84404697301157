import { MELIDATA_DEFAULTS } from './constants';

const {
  NONE,
  PATH_EVENT,
  PATH_SHOW,
  NO_APPLY,
  STREAM_NAME,
  TRACK_TYPE: { EVENT, SHOW },
  MODE: { CLEAN_SEND },
  VALID_PATH_CONVENTION_REGEX,
} = MELIDATA_DEFAULTS;

const getCalmEventDataProps = (viewCalmTrack, calmTrackdata) => {
  const {
    type,
    sectionId,
    sectionVersionId,
    element: triggerElement,
    referer,
    conversion = false,
  } = calmTrackdata;

  return {
    ...viewCalmTrack.event_data,
    module_referer: referer || NO_APPLY,
    section_name: type || NONE,
    section_id: sectionId ? String(sectionId) : NONE,
    section_version_id: sectionVersionId ? String(sectionVersionId) : NONE,
    conversion,
    element: triggerElement || NONE,
  };
};

const handleCalmShowTrack = (
  viewCalmTrack,
  calmTrackdata,
  experiments,
  melidataFn,
) => {
  const trackData = {};
  const eventData = getCalmEventDataProps(viewCalmTrack, calmTrackdata);

  melidataFn('createShowTrack', trackData);
  melidataFn('withPath', PATH_SHOW, trackData);
  melidataFn('withStream', STREAM_NAME, trackData);
  melidataFn('withData', eventData || {}, trackData);

  if (experiments) {
    melidataFn('withExperiments', experiments, trackData);
  }

  melidataFn('sendTrack', trackData);
};

const handleCalmTrackMelidata = (viewCalmTrack, calmTrackdata, melidataFn) => {
  const data = {
    ...viewCalmTrack,
    path: PATH_EVENT,
    event_data: getCalmEventDataProps(viewCalmTrack, calmTrackdata),
  };

  melidataFn(CLEAN_SEND, EVENT, data);
};

export const handleTrackMelidata = (
  customTrackdata,
  calmTrackdata,
  trackType,
) => {
  const { melidata: melidataFn, __PRELOADED_STATE__: state = {} } =
    window || {};

  if (melidataFn) {
    const viewTrackData = state.landingConfig?.tracking?.melidata || {};
    const { customTrack: viewCustomTrack, calmTrack: viewCalmTrack } =
      viewTrackData;

    if (trackType === SHOW) {
      handleCalmShowTrack(
        viewCalmTrack,
        calmTrackdata,
        viewCustomTrack?.experiments,
        melidataFn,
      );

      return;
    }

    const { melidata: customMelidata = {} } = customTrackdata || {};

    const validPathConvention = VALID_PATH_CONVENTION_REGEX.test(
      customMelidata?.path,
    );

    // If valid path convention skip calm track
    if (!validPathConvention) {
      handleCalmTrackMelidata(viewCalmTrack, calmTrackdata, melidataFn);
    }

    if (Object.keys(customMelidata || {}).length) {
      const {
        experiments,
        path: customPath = '',
        event_data: customEventData = {},
      } = viewCustomTrack;

      const { reply_data: replyData, ...melidata } = customMelidata;
      const isInheritingPath = melidata.path.startsWith(customPath);

      const track = {
        ...melidata,
        event_data: {
          ...melidata.event_data,
        },
      };

      if (experiments) {
        track.experiments = experiments;
      }

      if (validPathConvention) {
        track.event_data = {
          ...track.event_data,
          ...getCalmEventDataProps(viewCalmTrack, calmTrackdata),
        };
      }

      if (
        replyData &&
        isInheritingPath &&
        Object.keys(customEventData).length
      ) {
        track.event_data = {
          ...track.event_data,
          ...customEventData,
        };
      }

      melidataFn(CLEAN_SEND, EVENT, track);
    }
  }
};

export const handleTrackGTM = (gtmConfig, gtmViewConfig) => {
  const { reply_data: replyData, ...gtmData } = gtmConfig || {};
  const { dataLayer: gtmDataLayer } = window || {};

  if (gtmData && gtmDataLayer) {
    const gtmEventData = {
      ...(replyData ? { ...gtmViewConfig.dataLayer[0] } : {}),
      event: gtmData.event,
      ...gtmData.additional_data,
    };

    gtmDataLayer.push(gtmEventData);
  }
};
